import React from 'react';
// import { Date, Link, RichText } from 'prismic-reactjs'

import v from '../img/v.svg';
import o from '../img/o.svg';
import w from '../img/w.svg';

// import Button from '../Components/Button.js';
// import Parallax from '../Functions/Parallax.js';

import '../styles/Where.scss';
import visingso from '../img/visingso.gif';

const Where = ({data}) => {

  const src = "https://images.prismic.io/open-water/ca03425e-35e0-4778-b336-1ee2c33f650e_water.jpg?auto=compress,format";

  function getLetters(){
    let letters = [];
    for (var i = 0; i < 5; i++) {
      letters.push (
        <div key={"letters-"+i} className="letters">
          <img className="letter" src={v} alt=""/>
          <img className="letter" src={o} alt=""/>
          <img className="letter" src={w} alt=""/>
        </div>
      )
    }
    return letters;
  }

  return (
    <div className="where abs-bg hide">
      <div className="hero-image hero-image--blue">


          <img src={src} alt="" className="bg-img"/>

        <div className=" animated-letters animated-letters--small">

        { getLetters()}
        </div>

      </div>
      <div className="inner content">
        <figure className="map">
          <img src={visingso} alt=""/>
        </figure>

        <div className="coords">
          N 58 ° 3 ’ 10.169 ”  E 14 ° 20 ’ 40.107 ”
        </div>
      </div>
    </div>
  )
}

export default Where;
