// import React, { Component } from 'react';

function getScrollPos(){
  let singlePageOpen = document.querySelector('.page--single') || false;
  if(singlePageOpen){
    return singlePageOpen.scrollTop;
  }
  else {
    return (window.scrollY !== undefined) ? window.scrollY : document.documentElement.scrollTop;

  }
}

function calcWindowSize(){
  let singlePageOpen = document.querySelector('.single-article') || false;
  if(singlePageOpen){
    console.log('singlePageOpen', singlePageOpen);

    console.log('singlePageOpen.offsetHeight: ', singlePageOpen.offsetHeight);

    return {
      pageHeight: singlePageOpen.offsetHeight,
      height: window.innerHeight,
      width: document.body.offsetWidth
    }
  }
  else {
    return {
      pageHeight: document.body.offsetHeight,
      height: window.innerHeight,
      width: document.body.offsetWidth
    }
  }
}

var WindowProps = {
  SIZE: calcWindowSize(),
  SCROLLTOP: getScrollPos(),

  handleScroll(){
    this.SCROLLTOP = getScrollPos();
  },
  updateSize(){
    this.SIZE = calcWindowSize();
  },
  componentDidMount(){
    console.log('did mount');
  }
}
export default WindowProps;
