
import React from 'react';
import { RichText } from 'prismic-reactjs'
import '../styles/Quote.scss';
import PropTypes from 'prop-types';
// import AnimateLetters from '../Functions/AnimateLetters.js';


const Quote = ({text, author, bg}) => {

  // const quote = RichText.render(text);
  const quoteData = RichText.asText(text);
  const size = parseInt(quoteData.length/4)+20;

  let re = new RegExp('.{1,' + size + '}', 'g');
  let lines = quoteData.match(re);

  return (
    <div className={bg + " scroll-point quote-bgg rel"} data-quote={quoteData}>

      <div className="line-bg">
        <div className="line-bg__top">
          <span>{lines[0]} {lines[1]}</span>
          <span>{lines[2]} {lines[3]}</span>
        </div>
        <div className="line-bg__bottom">
        <span>{lines[0]} {lines[1]}</span>
        <span>{lines[2]} {lines[3]}</span>
        </div>
      </div>

      <div className="container">
        <div className="inner quote block ">
          <span className="read-in-line">{lines[0]}</span>
          <span className="read-in-line">{lines[1]}</span>
          <span className="read-in-line">{lines[2]}</span>
          <span className="read-in-line">{lines[3]}</span>
          <span className="read-in-line author">{author}</span>
        </div>
      </div>
    </div>
  )
};


Quote.propTypes = {
  text: PropTypes.array.isRequired,
  author: PropTypes.string.isRequired,
  bg: PropTypes.string
};
export default Quote;
