import React from 'react';
import { Link, RichText } from 'prismic-reactjs'
import DataByType from '../Functions/Data.js';

import '../styles/Sponsors.scss';

const Sponsors = () => {

  const data = DataByType('sponsor');
  if(!data)return false;
  const content = data[0].data;
  const sponsors = content.body[0].items;

  return (
    <div id="sponsors" className="sponsors scroll-point freeze">
    <header>
      <h3>{RichText.asText( content.title )}</h3>
    </header>
      <ul className="sponsors__list">
      {sponsors.map((item,key) => (

        <li key={"sponsors-" +key} className="fade-nth-in--row">
          <a href={Link.url(item.sponsor_link)}><img src={item.sponsor_logotype.url} alt={RichText.asText(item.sponsor_name)}></img></a>
        </li>
      ))}
      </ul>
    </div>
  )
}

export default Sponsors;
