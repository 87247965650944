import React from 'react';
import Prismic from 'prismic-javascript'
// import { Date, Link, RichText } from 'prismic-reactjs'

const apiEndpoint = 'https://open-water.prismic.io/api/v2'
const accessToken = '' // This is where you would add your access token for a Private repository

const Client = Prismic.client(apiEndpoint, { accessToken })

const Data = (type, query) => {
  const [doc, setDocData] = React.useState(null)
  let q = query || 'document.type';
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await Client.query(
        Prismic.Predicates.at(q, type)
      )
      if (response) {
        // console.log('response', response);
        setDocData(response.results)
      }
    }
    fetchData()
  })

  return doc
};

export default Data;
