


import React from 'react';
import Sponsors from '../Blocks/Sponsors.js';
import DataByType from '../Functions/Data.js';
// import logo from '../img/footer_logo.svg';
import logo2 from '../img/vow_logo_dark.svg';

import '../styles/Footer.scss';
import PropTypes from 'prop-types';

const Footer = ({links}) => {

  const data = DataByType('sponsor');
  if(!data)return false;
  // const content = data[0].data;

  return (
    <footer className="">
      <Sponsors />
      <div className="page-footer block bg--yellow">
        <div className="container inner">
          <h3>Visingsö Open Water</h3>
          <div className="flex-row flex--spread">
            <div className="flex-row flex--start">
              <nav className="flex-col">
              {
                links.map((link,i)=>{
                  return(
                    <a key={"footer-nav-link-"+i} href={"#" + link.id }>{link.text}</a>
                  )
                })
              }
              </nav>
              <nav className="flex-col">
                <h4>Kontakt</h4>

                <a href="mailto:susanne@persgarden.se">Epost</a>
                <a href="https://www.instagram.com/visingsoopenwater/">Instagram</a>
                <a href="https://www.facebook.com/Visings%C3%B6-Open-Water-110813523850745/">Facebook</a>

              </nav>
            </div>
            <div className="logo-wrapper">
              <img alt="logo" className="logo" src={logo2}/>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
Footer.propTypes = {
  links: PropTypes.array.isRequired
};
export default Footer;
