
import React from 'react';

const Logo = ({ color }) => (

    <svg viewBox="0 0 261.2 223.2" className={`svg-logo color-${color}`}>
    <path
      d="M230.2 38.8L207 114.7l-15.4-57.9H168l-15.6 57.5-23.3-75.4h-29L112 0H81.8L60.7 79.8 39.5 0H9.7l31.7 104C17.2 112.1 0 134.9 0 161.9c0 33.7 26.8 61.3 60.9 61.3 33.6 0 60.7-27.5 60.7-61.3 0-26.9-17.3-49.7-41.4-57.8L99.1 42l41.4 123.1H163l16.3-64.1 16.8 64.1h22.3l42.8-126.2-31-.1zM94.3 161.9c0 19.9-13.7 35.6-33.6 35.6-20.1 0-33.4-15.7-33.4-35.6s13.3-35.5 33.4-35.5c20 0 33.6 15.6 33.6 35.5z"
    />
    </svg>

);

export default Logo
