
import React from 'react';
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types';

const Columns = ({title, items, header}) => {

  return (
    <div className="container scroll-point">
      <div className="inner columns block">
        <header className="columns__header">
          <h2>{title}</h2>
          {header}
        </header>
        {
          Object.keys(items).map((i)=> {
            let item = items[i];
            const title = RichText.asText(item.list_title);
            const text = RichText.render(item.list_text);
            return(
              <div key={"items-"+i} className="columns__col--2 align-left">
                <h3>{title}</h3>
                {text}
              </div>
            )
          })
        }

      </div>
    </div>
  )
};
Columns.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};
export default Columns;
