import InView from './InView.js';
import WindowProps from './WindowProps.js';
import '../styles/ScrollPoints.scss';

let waitingOnAnimRequest = false;

function ScrollPoints(animationSelectors) {

// console.log('ScrollPoints: ', animationSelectors);

  window.addEventListener('scroll', (e)=>handleScroll( e ));

  const animChecker = (target) => {
    // Loop over our selectors
    animationSelectors.forEach(selector => {
      // Loop over all matching DOM elements for that selector

      target.querySelectorAll(selector).forEach(element => {
        setClasses(element);
      })
    })
  }

  let setClasses = (element) => {
    let offset = element.getAttribute('offset') || 0;

    if(isNaN(offset)){
      if ( offset.indexOf('%') >= 0 ){
        offset = offset.split('%')[0];
        offset = element.clientHeight * (offset/100);
      }
      else if ( offset.indexOf('vh') >= 0 ){
        offset = offset.split('vh')[0];
        offset = WindowProps.SIZE.height * (offset/100);
      }
    }

    if( InView(element, offset) ) {
      element.classList.add('scroll-point--active');
    }
    else {
      element.classList.remove('scroll-point--active');
    }
  }

  let handleScroll = ({target}) => {
    WindowProps.handleScroll();
    if (!waitingOnAnimRequest) {
      window.requestAnimationFrame(() => {
        animChecker(target);
        waitingOnAnimRequest = false;
      })
      waitingOnAnimRequest = true;
    }
  }

  animChecker(document);

}

export default ScrollPoints;
