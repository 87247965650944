


import React from 'react';

import DataByType from '../Functions/Data.js';
import { RichText } from 'prismic-reactjs'


import '../styles/Images.scss';

const Images = () => {

  const data = DataByType('images');
  // const data = DataByType(true,'document.type.images.data.items.show_on_startpage');
  if(!data)return false;

  const images = data[0].data.body[0].items;
  const photo = RichText.asText(data[0].data.fotograf);
  // console.log('photo', photo);

  let highlights = [];

  images.map((image) => {

    if(image.show_on_startpage){

      highlights.push(image);
    }
    return true;
  });

  // const content = data[0].data;

// const items = ["1","2","3","4","5","6"];

  return (
    <div className="block bg--blue scroll-point">

      <div className="container">
        <div className="images">
          { (highlights[0] || highlights[1]) &&
            <div className="flex-row scroll-point scroll-point--inner">
            { highlights[0] &&
              <figure>

                  <img className="img-in" src={highlights[0].gallery_image.url} alt=""/>

              </figure>
            }
            { highlights[1] &&
              <figure>

                  <img className="img-in" src={highlights[1].gallery_image.url} alt=""/>


              </figure>
            }
            </div>
          }
          { highlights[2] &&
            <div className="flex-row scroll-point scroll-point--inner">
              <figure>
                <img className="img-in" src={highlights[2].gallery_image.url} alt=""/>
              </figure>
            </div>
          }

          { (highlights[3] || highlights[4]) &&
            <div className="flex-row scroll-point scroll-point--inner">
            { highlights[3] &&
              <figure>

                <img className="img-in" src={highlights[3].gallery_image.url} alt=""/>

              </figure>
            }
            { highlights[4] &&
              <figure>

                <img className="img-in" src={highlights[4].gallery_image.url} alt=""/>

              </figure>
            }
            </div>
          }
          <span className="photographer">Foto: {photo}</span>

        </div>
      </div>

    </div>
  )
}

// return (
//   <div className="block">
//
//     <div className="container">
//       <div className="images">
//         <div className="flex-row">
//           <figure>
//             <img src="http://placehold.it/900x500/400" alt=""/>
//           </figure>
//           <figure>
//             <img src="http://placehold.it/400x500/400" alt=""/>
//           </figure>
//         </div>
//         <div className="flex-row">
//           <figure>
//             <img src="http://placehold.it/1100x600/400" alt=""/>
//           </figure>
//         </div>
//       </div>
//     </div>
//
//   </div>
// )

export default Images;
