
import React, {Component} from 'react';

import '../styles/Form.scss';

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

class Form extends Component {

  constructor(props){
    super(props);

    this.state = {
      first_name: "",
      last_name: "",
      address: "",
      zip: "",
      city: "",
      ice: "",
      birthdate: "",
      email: "",
      checked: false
    }

  }

  handleInputChange = (e) => {
    let val = e.target.name === "checked" ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: val })
  }

  handleSubmit = (e) => {
    // e.preventDefault();

    console.log('submit', e);
    // let props = this.props;

    // const content = this.state;
    console.log({ "form-name": "registration", ...this.state });

    // const fethUrl = window.location.hostname === "localhost" ? "https://visingso-open-water.netlify.app/" : "/"

    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({ "form-name": "registration", ...this.state })
      })
      .then(() => {
        console.log('Success');
        this.props.handleSubmit();

      })
      .catch(error => alert(error));

    e.preventDefault();

  }
  render(){
    const {first_name, last_name, address, zip, city, ice, birthdate, email} = this.state;
    return (
      <div className="form-wrapper">
        <form onSubmit={this.handleSubmit}>
          <input type="hidden" name="form-name" value="registration" />
          <input required placeholder="Förnamn" type="text" value={first_name} onChange={this.handleInputChange} name="first_name" />
          <input required placeholder="Efternamn" type="text" value={last_name} onChange={this.handleInputChange} name="last_name" />
          <input required placeholder="Adress" type="text" value={address} onChange={this.handleInputChange} name="address" />
          <div className="flex-row">
            <input required placeholder="Postnr." type="text" value={zip} onChange={this.handleInputChange} name="zip" />
            <input required placeholder="Stad" type="text" value={city} onChange={this.handleInputChange} name="city" />
          </div>
          <input required placeholder="Personnummer" type="text" value={birthdate} onChange={this.handleInputChange} name="birthdate" />
          <input required placeholder="E-post" type="email" value={email} onChange={this.handleInputChange} name="email" />
          <input required placeholder="ICE-kontakt (namn & telefonnummer)" type="text" value={ice} onChange={this.handleInputChange} name="ice" />

          <div className="checkbox-wrapper">
            <input id="terms" required type="checkbox" checked={this.state.checked} name="checked" onChange={this.handleInputChange} />
            <label htmlFor="terms">

              Jag godkänner att Visingsö Open Water sparar mina personuppgifter som underlag för tävlingen. Visingsö Open Water kommer inte att lämna uppgifterna vidare. Jag godkänner att det fotograferas under tävlingsdagen och att Visingsö Open Water har rättigheter att använda bilderna fritt i marknadsföringssyfte.
              </label>
          </div>
          <button className={this.state.checked ? "btn" : "btn disabled"} type="submit">Skicka</button>

        </form>
      </div>
    )
  }
}

export default Form;
