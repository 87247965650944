import React, {Component} from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
// import logo from './logo.svg';
import './styles/App.scss';

// import Nav from './Components/Nav.js';
import ScrollPoints from './Functions/ScrollPoints.js';
// import Cursor from './Functions/Cursor.js';


// Templates
import Main from './Templates/Main.js';
import Competition from './Templates/Competition.js';

class App extends Component {
  constructor(props){
    super(props);

    this.navItems = [
      {
        "text":"Anmälan",
        "id":"registration"
      },
      {
        "text":"Om tävlingen",
        "id":"about"
      },
      {
        "text":"Bra att veta",
        "id":"info"
      },
      {
        "text":"Sponsorer",
        "id":"sponsors"
      }
    ]

    this.state = {
      loading:true
    }
  }

  componentDidMount(){
    ScrollPoints(['.scroll-point']);

    setTimeout(function () {
      this.setState({
        loading:false
      })
    }.bind(this), 500);
  }

  render(){
    return (
      <div>

        <Router>
          <Switch>
            <Route exact path="/">
              <Main loaded={this.state.loading} links={this.navItems} />
            </Route>
            <Route path="/anmalan">
              <Competition />
            </Route>
          </Switch>
        </Router>


      </div>
    )
  }
}

export default App;
