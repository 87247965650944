import React from 'react';
import DataByType from '../Functions/Data.js';

import Hero from '../Blocks/Hero.js';
import Registration from '../Blocks/Registration.js';
// import Form from '../Components/Form.js';
import About from '../Blocks/About.js';
import Info from '../Blocks/Info.js';
import Quote from '../Components/Quote.js';
import Nav from '../Components/Nav.js';

import Where from '../Blocks/Where.js';

import Footer from '../Blocks/Footer.js';

const Main = ({loading, links}) => {

  const data = DataByType('header');
  const regData = DataByType('registration');
  const quoteData = DataByType('quote');


  if(!data || !regData || !quoteData )return false;
  const quote = quoteData[0].data;



  const quoteText = quote.text;
  const quoteAuthor = quote.author;

  return (
    <main className={loading ? "page loading" : "page"}>
      <Hero data={data} links={links}/>
      <Nav links={links} />

      <Registration data={regData} />
      <About />
      <Info />
      <Quote bg="bg--pink" text={quoteText} author={quoteAuthor} />
      <Where />

      <Footer links={links}/>

    </main>
  )
}

export default Main;
