import React from 'react';
import DataByType from '../Functions/Data.js';

import Hero from '../Blocks/Hero.js';
import Form from '../Components/Form.js';

const Competition = () => {
  const data = DataByType('XnCBbhAAACQAS9hb', 'document.id');

  console.log('Competition data', data);

  if(!data)return false;

  return (
    <main className="page page-competition">
      <Hero data={data} />
      <section className="form bg--blue">

        <Form />
      </section>

    </main>
  )
}

export default Competition;
