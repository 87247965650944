


import React from 'react';
import {  RichText } from 'prismic-reactjs'

import DataByType from '../Functions/Data.js';

import '../styles/About.scss';
// import MediaPlayer from '../Blocks/MediaPlayer.js';
import Columns from '../Components/Columns.js';
// import Images from '../Blocks/Images.js';

const Info = () => {

  const data = DataByType('info');
  if(!data)return false;
  const content = data[0].data;
  const info = content.info;

  const title = RichText.asText(content.title);

  return (
    <div id="info" className="scroll-point ">
      <div className="info bg-vow ">
        <Columns title={title} items={info} />
      </div>
    </div>
  )
}

export default Info;
