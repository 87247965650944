Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};

export function scrollToId(id, duration, changeHash) {
   changeHash=changeHash || false;
   var root = document.scrollingElement || document.documentElement;
   const scrollTo = document.getElementById(id).offsetTop - 100;

   var start = root.scrollTop,
       change = scrollTo - start,
       currentTime = 0,
       increment = 20;

   var animateScroll = function(){
       currentTime += increment;
       var val = Math.easeInOutQuad(currentTime, start, change, duration);
       root.scrollTop = val;
       if(currentTime < duration) {
           setTimeout(animateScroll, increment);
       }
       else {
         if(changeHash){
           window.location.hash = id;
         }
       }
   };
   animateScroll();
 }
