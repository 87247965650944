


import React from 'react';

// import DataByType from '../Functions/Data.js';
import PropTypes from 'prop-types';

import '../styles/MediaPlayer.scss';

const MediaPlayer = ({url}) => {

  let src = "https://www.youtube.com/embed/" + url.split('.be/')[1];

  // const data = DataByType('sponsor');
  // if(!data)return false;
  // const content = data[0].data;

  return (
    <div className="media block bg--pink scroll-point scroll-point--inner">


      <div className="container columns">

        <div className="inner">
        <div className="media-wrapper ease-in">
          <iframe title="VOW" src={src} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen className="embedd"></iframe>
        </div>
        </div>

      </div>

    </div>
  )
}
MediaPlayer.propTypes = {
  url: PropTypes.string.isRequired
};
export default MediaPlayer;
