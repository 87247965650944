import React, {Component} from 'react';
// import Prismic from 'prismic-javascript'
import { RichText } from 'prismic-reactjs'
import Button from '../Components/Button.js';
import Form from '../Components/Form.js';
import {linkResolver} from '../utils/linkResolver.js';
import '../styles/Registration.scss';
import visingso from '../img/visingso.gif';
import videoBg from '../videos/upplev_kanslan-gul.mp4';
import {scrollToId} from '../Functions/ScrollToId.js';

class Registration extends Component {
  constructor(props){
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);

    this.state = {
      formOpen:false,
      formSubmitted:false
    }
  }

  handleClick(){
    if(!this.state.formOpen){
      scrollToId("about",600)
    }
    this.setState({
      formOpen: !this.state.formOpen
    })

  }

  handleFormSubmit(){
    this.setState({
      formSubmitted: true
    })
  }

  render(){
    if(!this.props.data)return false;
    let data = this.props.data[0].data;

    const regOpen = data.registration_open;
    const regFull = data.registration_full;

    var title = RichText.render(data.title)
    var text = RichText.render(data.text, linkResolver)
    var regText = data.registration_text;

    return (
      <article id="registration" className={this.state.formOpen ? "form-open registration " : " registration "}>

        <div className="rel">
          <video muted controls={false} playsInline autoPlay loop src={videoBg} className="fill video-format"/>

          <header className="content block large-text fade-in abs-bottom">
            <Button text="Anmäl dig" onClick={this.handleClick} />
          </header>
        </div>


        <div className="block bg--blue flex-row form-block" id="regForm">
          <section className={this.state.formSubmitted ? "form submitted" : "form"}>
            {title}
            { (!regOpen && regFull) &&
              <p>
                Fullbokat
              </p>
            }
            { (!regOpen && !regFull) &&
            <p>
              {regText}
            </p>
            }
            {(regOpen && !regFull) &&
              <div>
                <div className="form__info">{text}</div>

                <Form handleSubmit={this.handleFormSubmit} />

                <div className={this.state.formSubmitted ? "form-message" : "form-message hide"}>
                  Du är nu anmäld, lycka till!<br/>
                  <b>Får du inget bekräftelsemail? Kolla även din skräppost så att det inte hamnat där. </b>
                </div>
              </div>
            }
          </section>
          <div>

            <figure className="">
              <img src={visingso} alt=""/>
            </figure>
          </div>
        </div>

      </article>
    )
  }
}

export default Registration;
