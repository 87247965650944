

import React from 'react';
import { RichText } from 'prismic-reactjs'

import DataByType from '../Functions/Data.js';

import '../styles/About.scss';
import MediaPlayer from '../Blocks/MediaPlayer.js';
import Columns from '../Components/Columns.js';
import Images from '../Blocks/Images.js';

const About = () => {

  const data = DataByType('block');
  if (!data) return false;
  const content = data[0].data;
  // console.log('content: ', content);
  const info = content.info;
  const header = RichText.render(content.rte);
  const videoSrc = content.video.embed_url;

  const title = RichText.asText(content.title);

  return (
    <div id="about" className="scroll-point">
      <div className="about bg-slice">
        <Columns title={title} header={header} items={info} />
        <MediaPlayer url={videoSrc} />
        <Images />
      </div>
    </div>
  )
}

export default About;
