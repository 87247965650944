import React from 'react';
import { RichText } from 'prismic-reactjs'
import {linkResolver} from '../utils/linkResolver.js';
// import v from '../img/v.svg';
// import o from '../img/o.svg';
// import w from '../img/w.svg';
// import videoBg from '../videos/visingso_vattenbubblor.mp4';
import videoBg from '../videos/bubblor.mp4';
import logo from '../img/VOW_vit.svg';

// import Button from '../Components/Button.js';
import WindowProps from '../Functions/WindowProps.js';
// import AnimateLetters from '../Functions/AnimateLetters.js';

import '../styles/Hero.scss';

Math.easeInOutQuad = function (t, b, c, d) {
  t /= d/2;
  if (t < 1) return c/2*t*t + b;
  t--;
  return -c/2 * (t*(t-2) - 1) + b;
};
const Header = ({data, links}) => {

  const content = data[0].data;

  var title = content.title ? RichText.asText(content.title) : false;
  var subtitle = content.subtitle ? RichText.asText(content.subtitle) : false;
  var date = subtitle;
  var text = content.rte ? RichText.render(content.rte, linkResolver) : false;
  // var more = content.text ? RichText.render(content.text, linkResolver) : false;
  // var more = false;
  // var styles = {};


  var img = document.querySelectorAll('.bg-img')[0];
  setTimeout(function () {
    img = document.querySelectorAll('.bg-img')[0];

  }, 1000);

  window.addEventListener('scroll', (e)=>{
    // console.log(WindowProps.SCROLLTOP, img);
    if(!img)return false;
    // img.style.cssText = "transform:translateY("+WindowProps.SCROLLTOP/3+"px); filter:blur("+ WindowProps.SCROLLTOP/100 +"px)"
    img.style.cssText = "transform:translateY("+WindowProps.SCROLLTOP/3+"px);"
  });
  function scrollToId(id, duration, changeHash) {
     changeHash=changeHash || false;
     var root = document.scrollingElement || document.documentElement;
     const scrollTo = document.getElementById(id).offsetTop - 100;

     var start = root.scrollTop,
         change = scrollTo - start,
         currentTime = 0,
         increment = 20;

     var animateScroll = function(){
         currentTime += increment;
         var val = Math.easeInOutQuad(currentTime, start, change, duration);
         root.scrollTop = val;
         if(currentTime < duration) {
             setTimeout(animateScroll, increment);
         }
         else {
           if(changeHash){
             window.location.hash = id;
           }
         }
     };
     animateScroll();
   }



  function handleLinkClick(e){
    console.log('klick, go to');
    e.preventDefault();

    let target = e.currentTarget;
    let id = target.getAttribute('href').split('#')[1];
    console.log('klick', id);
    scrollToId(id, 500, false)

  }


  return (
    <header id="top" className="hero abs-bg">

    <nav className="hero-menu">

      {
        links.map((item,i)=>{
        return (
          <a key={"menu-link-"+i} onClick={handleLinkClick} href={"#" + item.id}>{ item.text }</a>
        )
        })
      }
    </nav>

      <div className={text ? "hero-image--half" : "hero-image"}>
        <video muted controls={false} playsInline autoPlay loop src={videoBg} className="bg-img"/>

      </div>

      {text ?
        <div className="text-content--block">

          <h1 className="title--medium">
          {title}

          {subtitle &&
            <span>
              {subtitle}
            </span>
          }
          </h1>

          {text}
        </div> :
        <div className="text-content">
          <div className="logo-wrapper">
            <img className="logo" alt="logo" src={logo}/>
          </div>
          <h1 className="title--hero change-text">
            <span className="change-text__content">{title}</span>
            <span className="change-text__content">{date}</span>
          </h1>

        </div>
      }



    </header>
  )
}

export default Header;
